import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.scss';

class DefaultLayout extends Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {
            const { site } = data;
            return (
                <div className="davidow-io-root">
                    <Helmet
                        title={site.siteMetadata.title}
                        meta={[
                            { name: 'description', content: 'Will Davidow' },
                            { name: 'keywords', content: 'will, davidow will davidow, willdavidow, davewillidow' },
                        ]}
                    >
                        <html lang="en" />
                    </Helmet>
                    <Header />
                    <div className="main-content">
                        {this.props.children}
                    </div>
                </div>
            );
        }}
    />
        );
    }
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
