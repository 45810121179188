import React, { PureComponent } from 'react';

import Logo from './logo';

const baseClass = 'dio-global-header';

class Header extends PureComponent {
  render() {
    const { pageHeading } = this.props;

    return (
      <div
        className={baseClass}>
        <h1 className={`${baseClass}__h1`}>
          {pageHeading}
        </h1>
        <Logo />
      </div>
    );
  }
}

export default Header;
