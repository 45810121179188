import React, { PureComponent } from 'react';
import Link from 'gatsby-link';
import cx from 'classnames';
import libmoji from 'libmoji';

import './logo.scss';

const baseClass = 'dio-logo';

class Logo extends PureComponent {
  constructor() {
    super();

    this.state = {
      bitmoji: null
    };
  }

  componentDidMount() {
    const WILL_AVATAR_ID = '271575875_3-s1';
    // const STEF_AVATAR_ID = '134168621_67-s1';
    // const stefId = libmoji.getAvatarId('https://render.bitstrips.com/v2/cpanel/8968038-134168621_67-s5-v1.png');
    // const willId = libmoji.getAvatarId('https://render.bitstrips.com/v2/cpanel/8968038-271575875_3-s1-v1.png');
    const filterConfig = {
      MLB: {
        id: [
          1018190 // Boston Red Sox
        ]
      },
      NFL: {
        id: [
          1018373 // NE Patriots
        ]
      },
      NHL: {
        id: [
          1018433 // Pittsburgh Penguins
        ],
        description: [
          'Boston Bruins',
          'New Jersey Devils',
          'New York Islanders',
          'Pittsburgh Penguins', // not in the template data, but fuck the penguins...
          'Philadelphia Flyers',
          'Washington Capitals'
        ]
      }
    };
    const filteredBrands = libmoji.filterBrands(libmoji.getBrands('male'), filterConfig);
    // console.log('filtered brands', filteredBrands);
    // console.log('filtered brands', libmoji.filterBrands(libmoji.getBrands('male'), {
    //   NHL: {
    //     description: [
    //       'Boston Bruins',
    //       'New Jersey Devils',
    //       'New York Islanders',
    //       'Pittsburgh Penguins',
    //       'Philadelphia Flyers',
    //       'Washington Capitals'
    //     ]
    //   }
    // }, true));
    const comicId = libmoji.getComicId(libmoji.randTemplate(libmoji.templates));
    const outfit = libmoji.randOutfit(libmoji.getOutfits(libmoji.randBrand(filteredBrands)));
    const bitmoji = libmoji.buildRenderUrl(comicId,WILL_AVATAR_ID,1,1,outfit);

    // const friendComicId = libmoji.getComicId(libmoji.randTemplate(libmoji.friends));
    // const friendMoji = libmoji.buildFriendmojiUrl(friendComicId,WILL_AVATAR_ID,STEF_AVATAR_ID,1,1);

    // console.log('ffff ', friendMoji);

    this.setState({
      bitmoji
    });
  }

  maybeRenderBitmoji() {
    const { bitmoji } = this.state;
    const bitmojiClass = `${baseClass}__bitmoji`;
    const position = Math.floor(Math.random() * Math.floor(3));
    const enumPositions = [0, 1, 7];
    const classNames = cx(bitmojiClass, {
      [`${bitmojiClass}--center`]: position === 0,
      [`${bitmojiClass}--left`]: position === 1,
      [`${bitmojiClass}--right`]: position === 2,
    });

    if (bitmoji) {
      let direction = enumPositions[position];

      return (
        <img
          className={classNames}
          src={`${bitmoji}&body_rotation=${direction}&head_rotation=${direction}`}
          alt='BitmojiMe'
        />
      );
    }
  }

  render() {
    return (
      <div className={baseClass}>
        <Link
          className={`${baseClass}__main`}
          to="/"
        >
          <span className={`${baseClass}__tagline`}>DAVIDOW</span>
          <div className={`${baseClass}__panel ${baseClass}__panel--ny`}>
            <span className={`${baseClass}__panel-copy`}>NEW YORK</span>
          </div>
          <div className={`${baseClass}__panel ${baseClass}__panel--ct`}>
            <span className={`${baseClass}__panel-copy`}>CONNECTICUT</span>
          </div>
          <div className={`${baseClass}__panel ${baseClass}__panel--tx`}>
            <span className={`${baseClass}__panel-copy`}>TEXAS</span>
          </div>
          {this.maybeRenderBitmoji()}
        </Link>
      </div>
    );
  }
}

export default Logo;
