import { connect } from 'react-redux';
import Header from './header';

import './header.scss';

const mapStateToProps = state => {
    const { pageHeading } = state.app;

    return {
        pageHeading
    };
}

const ConnectedHeader = connect(
    mapStateToProps
)(Header);

export default ConnectedHeader;
